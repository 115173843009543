<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 协会名称 -->
					<el-form-item label="协会名称" prop="title">
						<div class="aaaa">
							<el-input v-model="form.title" placeholder="请填写协会名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片" prop="breviary">
						<el-upload action="/admin/api/v1/activity/saveImage" list-type="picture-card" limit="1"
							v-model="form.breviary" :auto-upload="true" :on-change="change" :on-error="bedefeated"
							:on-success="handleUpImage" :on-remove="Remove" :class="styA==0&&styD==0?'styB':'styC'"
							:before-upload="beforeImageUpload" :before-remove="beforeremove" :on-preview="onpreview"
							:file-list="approve" accept=".jpeg,.png,.jpg,.bmp,.gif" :headers="header" name="imageFile">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽750px、高350px。)
						</div>
					</el-form-item>
					<!-- 联系人姓名 -->
					<el-form-item label="联系人姓名" prop="contactsName">
						<div class="aaaa">
							<el-input v-model="form.contactsName" placeholder="请填写联系人姓名" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 协会是否开启报名 -->
					<el-form-item label="协会是否开启报名" prop="isStartSigningUp">
						<el-select v-model="form.isStartSigningUp" placeholder="请选择协会是否开启报名" size="medium">
							<el-option label="开启" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="关闭" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 报名时间 -->
					<el-form-item label="报名时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 所属工会 -->
					<el-form-item label="所属工会" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 会员报名限制 -->
					<el-form-item label="会员报名限制" prop="isConstraintSignUp">
						<el-select v-model="form.isConstraintSignUp" placeholder="请选择会员报名限制" size="medium">
							<el-option label="全员报名" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="老会员报名" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 协会是否免费 -->
					<el-form-item label="协会是否免费" prop="isFree">
						<el-select v-model="form.isFree" placeholder="请选择协会是否免费" size="medium">
							<el-option label="免费" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="收费" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 收费模式 -->
					<el-form-item label="收费模式" prop="chargeMode" v-if="form.isFree==2 || form.isFree== '收费'">
						<el-select v-model="form.chargeMode" placeholder="请选择收费模式" size="medium">
							<el-option label="自有商户收款" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="平台代收" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 费用 -->
					<el-form-item label="费用" prop="cost" v-if="form.isFree==2 || form.isFree== '收费'">
						<div class="aaaa">
							<el-input v-model="form.cost" placeholder="请填写费用" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 计费模式 -->
					<el-form-item label="计费模式" prop="priceMode" v-if="form.isFree==2 || form.isFree== '收费'">
						<el-select v-model="form.priceMode" placeholder="请选择计费模式" size="medium">
							<el-option label="全价收费" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="半价收费" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制到期时间 -->
					<el-form-item label="限制到期时间" prop="isConstraintExpirationTime">
						<el-select v-model="form.isConstraintExpirationTime" placeholder="请选择限制到期时间" size="medium">
							<el-option label="限制" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不限制" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 到期时间 -->
					<el-form-item label="到期时间" prop="value2" v-if="form.isConstraintExpirationTime==1 || form.isConstraintExpirationTime== '限制'">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value2" type="datetime" placeholder="到期时间"
								:default-time="defaultTime1" />
						</div>
					</el-form-item>
					<!-- 是否启用 -->
					<el-form-item label="是否启用" prop="isEnable">
						<el-select v-model="form.isEnable" placeholder="请选择是否启用" size="medium">
							<el-option label="启用" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="停用" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 协会可见范围 -->
					<el-form-item label="协会可见范围" prop="visibleRange">
						<el-select v-model="form.visibleRange" placeholder="请选择协会可见范围" size="medium">
							<el-option label="全部可见" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="本工会可见" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 最大会员数量 -->
					<el-form-item label="最大会员数量" prop="maxMembersNumber">
						<div class="aaaa">
							<el-input v-model="form.maxMembersNumber" onkeyup="value=value.replace(/[^\d]/g,'')"
								size="large" placeholder="请填写最大会员数量"></el-input>
						</div>
					</el-form-item>
					<!-- 排序 -->
					<el-form-item label="排序" prop="sort">
						<div class="aaaa">
							<el-input v-model="form.sort" type="number" placeholder="请填写排序值" size="large"></el-input>
						</div>
						<div class="referrals">
							（提示：大到小 默认100）
						</div>
					</el-form-item>
					<!-- 协会公告 -->
					<el-form-item label="协会公告" prop="introduce">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.introduce" placeholder="请填写协会公告"></el-input>
						</div>
					</el-form-item>
					<!-- 协会介绍 -->
					<el-form-item label="协会介绍" prop="notice">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.notice" placeholder="请填写协会介绍"></el-input>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading" class="submit"
				@click="onSubmit">提交</el-button>
			<!-- 所属工会选择 -->
			<div>
				<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
					<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
				</s3-layer>
			</div>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive,
		onActivated,
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		ElMessage
	} from "element-plus";
	import {
		associationEdit,
		ZClist,
		associationInfo,
	} from "../../utils/api"
	import {
		useRouter,
		useRoute
	} from "vue-router";
	// import { Pictures } from '../../utils/api'
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	import moments from 'moment'
	export default {
		name: "associationEdit",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			//获取列表页传来的id
			const route = useRoute()
			let loading = ref(true)
			let spChildIndex = ref(0)
			// 默认显示已上传文件
			let approve = ref([{}])
			//流程详情获取
			let associationInfos = () => {
				// console.log(sessionStorage.getItem('processIds'))
				let data = {
					associationId: sessionStorage.getItem('activityIds')
				}
				// var userNames = ref([])
				const id = ref([])
				associationInfo(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						// 活动名称
						form.title = res.data.data.associationInfo.title
						//主题图片
						approve.value[0].url = res.data.data.associationInfo.imageUrl
						form.breviary = res.data.data.associationInfo.imageUrl
						// 联系人姓名
						form.contactsName = res.data.data.associationInfo.contactsName
						//协会是否开启报名
						if (res.data.data.associationInfo.isStartSigningUp == 1) {
							form.isStartSigningUp = '开启'
						} else if (res.data.data.associationInfo.isStartSigningUp == 2) {
							form.isStartSigningUp = '关闭'
						}
						// 时间
						let staet = ref(moments(res.data.data.associationInfo.startTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						let enables = ref(moments(res.data.data.associationInfo.endTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						initiation.value = res.data.data.associationInfo.startTime
						terminate.value = res.data.data.associationInfo.endTime
						form.value1 = [staet.value, enables.value]
						// 选择工会
						gaincode.value = res.data.data.associationInfo.unionCode
						messge()
						//会员报名限制
						if (res.data.data.associationInfo.isConstraintSignUp == 1) {
							form.isConstraintSignUp = '全员报名'
						} else if (res.data.data.associationInfo.isConstraintSignUp == 2) {
							form.isConstraintSignUp = '老会员报名'
						}
						//协会是否免费
						if (res.data.data.associationInfo.isFree == 1) {
							form.isFree = '免费'
						} else if (res.data.data.associationInfo.isFree == 2) {
							form.isFree = '收费'
						}
						//限制到期时间
						if (res.data.data.associationInfo.isConstraintExpirationTime == 1) {
							form.isConstraintExpirationTime = '限制'
						} else if (res.data.data.associationInfo.isConstraintExpirationTime == 2) {
							form.isConstraintExpirationTime = '不限制'
						}
						//到期时间
						let expira = ref(moments(res.data.data.associationInfo.expirationTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						expireTime.value = res.data.data.associationInfo.expirationTime
						form.value2 = (expira.value)
						//收费模式
						if (res.data.data.associationInfo.chargeMode == 1) {
							form.chargeMode = '自有商户收款'
						} else if (res.data.data.associationInfo.chargeMode == 2) {
							form.chargeMode = '平台代收'
						}
						//是否启用
						if (res.data.data.associationInfo.isEnable == 1) {
							form.isEnable = '启用'
						} else if (res.data.data.associationInfo.isEnable == 2) {
							form.isEnable = '停用'
						}
						//可见范围
						if (res.data.data.associationInfo.visibleRange == 1) {
							form.visibleRange = '全部可见'
						} else if (res.data.data.associationInfo.visibleRange == 2) {
							form.visibleRange = '本工会可见'
						}
						//最大会员数量
						form.maxMembersNumber = res.data.data.associationInfo.maxMembersNumber
						//费用
						form.cost = res.data.data.associationInfo.cost
						//计费模式
						if (res.data.data.associationInfo.priceMode == 1) {
							form.priceMode = '全价收费'
						} else if (res.data.data.associationInfo.priceMode == 2) {
							form.priceMode = '半价收费'
						}
						//排序
						form.sort = res.data.data.associationInfo.sort
						//协会公告
						form.introduce = res.data.data.associationInfo.introduce
						//协会介绍
						form.notice = res.data.data.associationInfo.notice
					}
				})
			}
			// const route = useRoute();
			// 清空图片
			const qingkong = ref('')
			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)

			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				// Pictures(data).then((res)=>{
				//   console.log(res)
				// })
			}
			const styA = ref(0)
			const styD = ref(1)
			const change = (res, fileList) => {
				// console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 上传成功，获取返回的图片地址
			const handleUpImage = (res) => {
				location.value = res.data
				console.log(res, '成功')
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
				styD.value = 0
				form.breviary = ''
			}
			const Remove = (file) => {
				console.log(file)
			}
			// 获取工会列表信息
			const messge = () => {
				ZClist().then((res) => {
					texture.value = res.data.data.manageUnionList
					const arr3 = texture.value.filter(function(num) {
						return num.unionCode == gaincode.value
					})
					if (arr3.length == 0) {
						texture.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == gaincode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == gaincode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((ite) => {
											const arr6 = ite.unionDtoList.filter(
												function(numss) {
													return numss.unionCode ==
														gaincode.value
												})
											if (arr6.length == 0) {
												ite.unionDtoList.forEach((it) => {
													const arr7 = it
														.unionDtoList.filter(
															function(numsss) {
																return numsss
																	.unionCode ==
																	gaincode
																	.value
															})
													if (arr7.length == 0) {
														it.unionDtoList
															.forEach((i) => {
																const
																	arr8 =
																	i
																	.unionDtoList
																	.filter(
																		function(
																			numssss
																		) {
																			return numssss
																				.unionCode ==
																				gaincode
																				.value
																		})
																if (arr8
																	.length ==
																	0) {
																	i.unionDtoList
																		.forEach(
																			(
																				s
																			) => {
																				const
																					arr9 =
																					s
																					.unionDtoList
																					.filter(
																						function(
																							nu
																						) {
																							return nu
																								.unionCode ==
																								gaincode
																								.value
																						}
																					)
																				if (arr9
																					.length ==
																					0
																				) {
																					s.unionDtoList
																						.forEach(
																							(
																								a
																							) => {
																								const
																									arr10 =
																									a
																									.unionDtoList
																									.filter(
																										function(
																											cat
																										) {
																											return cat
																												.unionCode ==
																												gaincode
																												.value
																										}
																									)
																								if (arr10
																									.length ==
																									0
																								) {
																									a.unionDtoList
																										.forEach(
																											(
																												d
																											) => {
																												const
																													arr11 =
																													d
																													.unionDtoList
																													.filter(
																														function(
																															dog
																														) {
																															return dog
																																.unionCode ==
																																gaincode
																																.value
																														}
																													)
																												if (arr11
																													.length ==
																													0
																												) {
																													d.unionDtoList
																														.forEach(
																															(
																																f
																															) => {
																																const
																																	arr12 =
																																	f
																																	.unionDtoList
																																	.filter(
																																		function(
																																			long
																																		) {
																																			return long
																																				.unionCode ==
																																				gaincode
																																				.value
																																		}
																																	)
																																arr12
																																	.forEach(
																																		(
																																			item
																																		) => {
																																			form.choose =
																																				arr3
																																				.unionName =
																																				item
																																				.unionName
																																			gaincode
																																				.value =
																																				arr3
																																				.unionCode =
																																				item
																																				.unionCode
																																		}
																																	)
																															}
																														)
																												}
																												arr11
																													.forEach(
																														(
																															item
																														) => {
																															form.choose =
																																arr3
																																.unionName =
																																item
																																.unionName
																															gaincode
																																.value =
																																arr3
																																.unionCode =
																																item
																																.unionCode
																														}
																													)
																											}
																										)
																								}
																								arr10
																									.forEach(
																										(
																											item
																										) => {
																											form.choose =
																												arr3
																												.unionName =
																												item
																												.unionName
																											gaincode
																												.value =
																												arr3
																												.unionCode =
																												item
																												.unionCode
																										}
																									)
																							}
																						)
																				}
																				arr9.forEach(
																					(
																						item
																					) => {
																						form.choose =
																							arr3
																							.unionName =
																							item
																							.unionName
																						gaincode
																							.value =
																							arr3
																							.unionCode =
																							item
																							.unionCode
																					}
																				)
																			}
																		)
																}
																arr8.forEach(
																	(
																		item
																	) => {
																		form.choose =
																			arr3
																			.unionName =
																			item
																			.unionName
																		gaincode
																			.value =
																			arr3
																			.unionCode =
																			item
																			.unionCode
																	})
															})
													}
													arr7.forEach((item) => {
														form.choose =
															arr3
															.unionName =
															item
															.unionName
														gaincode
															.value =
															arr3
															.unionCode =
															item
															.unionCode
													})
												})
											}
											arr6.forEach((item) => {
												form.choose = arr3.unionName =
													item.unionName
												gaincode.value = arr3
													.unionCode = item.unionCode
											})
										})
									}
									arr5.forEach((item) => {
										form.choose = arr3.unionName = item.unionName
										gaincode.value = arr3.unionCode = item
											.unionCode
									})
								})
							} else {
								arr4.forEach((item) => {
									form.choose = arr3.unionName = item.unionName
									gaincode.value = arr3.unionCode = item.unionCode
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.choose = arr3.unionName = item.unionName
							gaincode.value = arr3.unionCode = item.unionCode
						})
					}
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			// 开始时间
			let initiation = ref('')
			// 结束时间
			let terminate = ref('')
			//到期时间
			let expireTime = ref('')
			const defaultTime1 = [
				new Date(2000, 1, 1, 0, 0, 0),
			]
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),

			]
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					associationInfos()
					messge()
				}
			})
			// 添加按钮是否显示
			// let styD = ref(0)
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {
				//开始时间
				var b = ref(new Date(form.value1[0]))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				// 截至时间
				var d = ref(new Date(form.value1[1]))
				var finish = ref('')
				finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value.getDate() + ' ' +
					d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value.getSeconds();
				var over = ref(new Date(finish.value).getTime() / 1000)
				//到期时间
				var e = ref(new Date(form.value2))
				var expire = ref('')
				expire.value = e.value.getFullYear() + '-' + (e.value.getMonth() + 1) + '-' + e.value.getDate() + ' ' +
					e.value.getHours() + ':' + e.value.getMinutes() + ':' + e.value.getSeconds();
				var expireTime = ref(new Date(expire.value).getTime() / 1000)
				//协会是否开启报名
				let isStartSigningUps = ref(null)
				if (form.isStartSigningUp == '开启') {
					isStartSigningUps.value = 1
				} else if (form.isStartSigningUp == '关闭') {
					isStartSigningUps.value = 2
				} else if (form.isStartSigningUp == 1) {
					isStartSigningUps.value = 1
				} else if (form.isStartSigningUp == 2) {
					isStartSigningUps.value = 2
				}
				//是否收费
				let isFrees = ref(null)
				if (form.isFree == '免费') {
					isFrees.value = 1
				} else if (form.isFree == '收费') {
					isFrees.value = 2
				} else if (form.isFree == 1) {
					isFrees.value = 1
				} else if (form.isFree == 2) {
					isFrees.value = 2
				}
				//收费模式
				let chargeModes = ref(null)
				if (form.chargeMode == '自有商户收款') {
					chargeModes.value = 1
				} else if (form.chargeMode == '平台代收') {
					chargeModes.value = 2
				} else if (form.chargeMode == 1) {
					chargeModes.value = 1
				} else if (form.chargeMode == 2) {
					chargeModes.value = 2
				}
				//是否启用
				let isEnables = ref(null)
				if (form.isEnable == '启用') {
					isEnables.value = 1
				} else if (form.isEnable == '停用') {
					isEnables.value = 2
				} else if (form.isEnable == 1) {
					isEnables.value = 1
				} else if (form.isEnable == 2) {
					isEnables.value = 2
				}
				//可见范围
				let visibleRanges = ref(null)
				if (form.visibleRange == '全部可见') {
					visibleRanges.value = 1
				} else if (form.visibleRange == '本工会可见') {
					visibleRanges.value = 2
				} else if (form.visibleRange == 1) {
					visibleRanges.value = 1
				} else if (form.visibleRange == 2) {
					visibleRanges.value = 2
				}
				//计费模式
				let priceModes = ref(null)
				if (form.priceMode == '全价收费') {
					priceModes.value = 1
				} else if (form.priceMode == '半价收费') {
					priceModes.value = 2
				} else if (form.priceMode == 1) {
					priceModes.value = 1
				} else if (form.priceMode == 2) {
					priceModes.value = 2
				}
				//会员报名限制
				let isConstraintSignUps = ref(null)
				if (form.isConstraintSignUp == '全员报名') {
					isConstraintSignUps.value = 1
				} else if (form.isConstraintSignUp == '老会员报名') {
					isConstraintSignUps.value = 2
				} else if (form.isConstraintSignUp == 1) {
					isConstraintSignUps.value = 1
				} else if (form.isConstraintSignUp == 2) {
					isConstraintSignUps.value = 2
				}
				//限制到期时间
				let isConstraintExpirationTimes = ref(null)
				if (form.isConstraintExpirationTime == '限制') {
					isConstraintExpirationTimes.value = 1
				} else if (form.isConstraintExpirationTime == '不限制') {
					isConstraintExpirationTimes.value = 2
				} else if (form.isConstraintExpirationTime == 1) {
					isConstraintExpirationTimes.value = 1
				} else if (form.isConstraintExpirationTime == 2) {
					isConstraintExpirationTimes.value = 2
				}
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						associationId: sessionStorage.getItem('activityIds'),
						// 协会名称
						title: form.title,
						// 主题图片
						imageInfo: location.value,
						//联系人姓名
						contactsName: form.contactsName,
						//协会是否开启报名
						isStartSigningUp: isStartSigningUps.value,
						//选择开始和截止时间
						startTime: start.value,
						endTime: over.value,
						//工会CODE
						unionCode: gaincode.value,
						//会员报名限制
						isConstraintSignUp:isConstraintSignUps.value,
						// 是否收费
						isFree: isFrees.value,
						//限制到期时间
						isConstraintExpirationTime:isConstraintExpirationTimes.value,
						//到期时间
						expirationTime:expireTime.value,
						//收费模式
						chargeMode: chargeModes.value,
						// 是否启用 
						isEnable: isEnables.value,
						//可见范围 
						visibleRange: visibleRanges.value,
						//会员最大数
						maxMembersNumber: form.maxMembersNumber,
						//费用
						cost: form.cost,
						//计费模式 
						priceMode:priceModes.value,
						//排序
						sort: form.sort,
						//协会公告
						introduce: form.introduce,
						//协会介绍
						notice: form.notice,
					}
					if (valid == true) {
						ConfirmLoading.value = true
						associationEdit(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'associationManagement',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							}else {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}

			// 模拟 ajax 异步获取内容
			// onMounted(() => {
			// 		messge()
			// 		associationInfos()
			// })

			const visible = ref(false);
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				title: "",
				breviary: "",
				contactsName:"",
				isStartSigningUp:"",
				value1: "",
				choose: "",
				isFree: "",
				chargeMode: "",
				isEnable:"",
				visibleRange: "",
				maxMembersNumber: "",
				cost:"",
				introduce:"",
				notice:"",
				priceMode:"",
				isConstraintExpirationTime:"",
				value2:"",
				isConstraintSignUp:"",
			})
			// 验证表单提示
			// 验证表单提示
			const rules = {
				title: [{
					required: true,
					message: "请填写协会名称",
					trigger: "blur"
				}, ],
				breviary: [{
					required: true,
					message: "请上传主题图片",
					trigger: "change"
				}, ],
				contactsName: [{
					required: true,
					message: "请填写联系人姓名",
					trigger: "blur"
				}, ],
				isStartSigningUp: [{
					required: true,
					message: "请选择协会是否开启报名",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择报名时间",
					trigger: "change"
				}, ],
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				isConstraintSignUp: [{
					required: true,
					message: "请选择会员报名限制",
					trigger: "change"
				}, ],
				isFree: [{
					required: true,
					message: "请选择协会是否免费",
					trigger: "change"
				}, ],
				isConstraintExpirationTime: [{
					required: true,
					message: "请选择限制到期时间",
					trigger: "change"
				}, ],
				value2: [{
					required: true,
					message: "请选择到期时间",
					trigger: "change"
				}, ],
				chargeMode: [{
					required: true,
					message: "请选择收费模式",
					trigger: "change"
				}, ],
				isEnable: [{
					required: true,
					message: "请选择是否启用",
					trigger: "change"
				}, ],
				visibleRange: [{
					required: true,
					message: "请选择协会可见范围 ",
					trigger: "change"
				}, ],
				maxMembersNumber: [{
					required: true,
					message: "请填写最大会员数量",
					trigger: "blur"
				}, ],
				cost: [{
					required: true,
					message: "请填写费用",
					trigger: "blur"
				}, ],
				priceMode: [{
					required: true,
					message: "请选择计费模式",
					trigger: "change"
				}, ],
				introduce: [{
					required: true,
					message: "请填写协会介绍",
					trigger: "blur"
				}, ],
				notice: [{
					required: true,
					message: "请填写协会公告",
					trigger: "blur"
				}, ],
			}
			return {
				qingkong,
				header,
				succ,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				httpreqst,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				styD,
				change,
				beforeImageUpload,
				onSubmit,
				// 标题验证
				ruleFormRef,
				form,
				rules,
				visible,
				texture,
				defaultProps,
				dendrogram,
				defaultTime1,
				defaultTime2,
				approve,
				value1: [],
				handleUpImage,
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 150px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 150px;
	}
</style>